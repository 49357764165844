<template>
  <v-container>

    <v-snackbar centered color="error" :value="this.error" timeout="5000">{{ this.error }}</v-snackbar>
    <v-form>
      
      <center><v-rating v-model="prio" hover length="5" size="40" class="mb-3"></v-rating></center>

      <v-menu v-model="date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dateFormatted" @blur="datum = parseDate(dateFormatted)" label="Datum" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-date-picker v-model="datum" @input="date_menu = false"></v-date-picker>
      </v-menu>

      <v-menu ref="tmenu" v-model="time_menu" :close-on-content-click="false" :nudge-right="40" :return-value.sync="t" transition="scale-transition" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="t" label="Zeit" prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"></v-text-field>
        </template>
        <v-time-picker v-if="time_menu" format="24hr" v-model="t" full-width @click:minute="$refs.tmenu.save(t)"></v-time-picker>
      </v-menu>
        <v-text-field outlined auto-grow clearable v-model="headline" label="Bezeichnung" hide-details class="mb-3"></v-text-field>
        <v-textarea outlined auto-grow clearable v-model="analyse" label="Analyse" hide-details class="mb-3"></v-textarea>
        <v-textarea outlined auto-grow clearable v-model="empfehlung" label="Empfehlung" hide-details class="mb-3"></v-textarea>
        <v-switch v-model="ziel_erreicht" :label="ziel_erreicht ? 'Ziel erreicht' : 'Ziel noch nicht erreicht'"></v-switch>
        <v-btn color="primary" class="mt-5" block @click="save">Speichern</v-btn>
        <v-btn color="grey" dark class="mt-1" block @click="back">Abbrechen</v-btn>
        <v-btn color="error" class="mt-10" block @click="del">Löschen</v-btn>
        <ul class="mt-2">
          <li class="text-caption" style="line-height:1rem" v-for="(view,idx) of viewings" :key="idx">{{ view }}</li>
        </ul>
    </v-form>
  </v-container>
</template>

<script>

import axios from 'axios';
  export default {
    data: () => ({
      date_menu: false,
      time_menu: false,
      uploading:false,
      analyse: null,
      empfehlung: false,
      headline: null,
      prio: null,
      ziel_erreicht: false,
      viewings:[],
      datum: "",
      t: "",
      error: null,
    }),
    props:["id","userid"],
    methods:{
      back(){
        this.$router.push('/user/'+this.userid)
      },
      async del(){
        await axios.delete(process.env.VUE_APP_APIURL+"item/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(() => {
            this.back()
        });
      },
      async save(){
        await axios.patch(process.env.VUE_APP_APIURL+"item/"+this.id,{
            "analyse" : this.analyse,
            "empfehlung": this.empfehlung,
            "headline": this.headline,
            "datum": this.datum,
            "zeit": this.t,
            "prio": this.prio,
            "ziel_erreicht": this.ziel_erreicht
        },{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(() => {
            this.back()
        });
      },
      async loadItem(){
        await axios.get(process.env.VUE_APP_APIURL+"item/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then(result => {
            this.analyse = result.data.result.analyse;
            this.empfehlung = result.data.result.empfehlung
            this.headline = result.data.result.headline
            this.datum = result.data.result.datum
            this.prio = Number(result.data.result.prio)
            this.ziel_erreicht = result.data.result.ziel_erreicht
            this.t = result.data.result.t
            this.viewings = result.data.result.viewings
        });
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('.')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
    computed:{
        dateFormatted(){
            if (!this.datum) return null
            const [year, month, day] = this.datum.split('-')
            return `${day}.${month}.${year}`
        }
    },
    mounted(){
      this.loadItem()
    },
  }
</script>
