import Vue from 'vue'
import VueRouter from 'vue-router'
import userlist from '../components/userlist.vue'
import user from '../components/user.vue'
import login from '../components/login.vue'
import adduser from '../components/adduser.vue'
import edititem from '../components/edititem.vue'
import edituser from '../components/edituser.vue'
import showitem from '../components/showitem.vue'
import sportdata_turnier_einlesen from '../components/sportdata_turnier_einlesen.vue'
import meine_wertungen from '../components/meine_wertungen.vue'
import meine_baustellen from '../components/meine_baustellen.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '*',
      name: 'Anmeldung',
      component: login,
    },
    {
      path: '/home',
      name: 'Übersicht',
      component: userlist,
    },
    {
      path: '/user/:id',
      name: 'Sportler',
      props: true,
      component: user,
    },
    {
      path: '/meine_wertungen/:id',
      name: 'Meine Wertungen',
      props: true,
      component: meine_wertungen,
    },
    {
      path: '/meine_baustellen/:id',
      name: 'Meine Baustellen',
      props: true,
      component: meine_baustellen,
    },
    {
      path: '/item/:id/:itemtoken',
      name: 'Sportler',
      props: true,
      component: user,
    },
    {
      path: '/edituser/:id',
      name: 'Sportler bearbeiten',
      props: true,
      component: edituser,
    },
    {
      path: '/showitem/:userid/:id',
      name: 'Eintrag anzeigen',
      props: true,
      component: showitem,
    },{
      path: '/edititem/:userid/:id',
      name: 'Eintrag bearbeiten',
      props: true,
      component: edititem,
    },
    {
      path: '/adduser',
      name: 'Benutzer anlegen',
      component: adduser,
    },
    {
      path: '/sportdata_turnier_einlesen',
      name: 'Sportdata Turnier einlesen',
      component: sportdata_turnier_einlesen,
    },
    {
      path: '/login',
      name: 'Anmeldung',
      component: login,
    }
  ]
})

const DEFAULT_TITLE = 'CoachMe';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = DEFAULT_TITLE+" - "+to.name|| DEFAULT_TITLE;
    });
});

export default router
