<template>
  <v-container>
    <v-dialog :value="mediadialog" hide-overlay fullscreen>
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Anzeigen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="current_video = false;current_image = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <video playsinline v-if="current_video" :src="current_video" controls autoplay width="100%"></video>
        <img v-if="current_image" :src="current_image" width="100%" height="100%" />
        <div v-if="current_video" v-html="current_video"></div>
      </v-card>
    </v-dialog>
    <v-expansion-panels accordion multiple v-if="myItems.length>0">
      <v-expansion-panel v-for="(item) in myItems" :key="item.id">
        <v-expansion-panel-header color="primary" class="white--text">
          <div>{{ item.headline ? item.headline : '&nbsp;' }}</div>
          <v-spacer></v-spacer>
          <div class="text-right pr-3"><v-rating v-if="item.prio" class="mt-n1" :value="Number(item.prio)" :length="item.prio" small readonly dense color="white"></v-rating></div>
          <template v-slot:actions>
            <v-icon color="white">$expand</v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2 pb-n1">
          <v-row>
              <v-col cols="4">
                <v-avatar v-if="item.thumb"><img :src="item.thumb"></v-avatar>
              </v-col>
              <v-col cols="8" class="text-right">
                <v-btn v-if="item.filetype" :color="itemColor(item)" @click="ansehen(item)" class="mx-0" fab small elevation="0">
                  <v-icon v-if="item.filetype=='video'" dark>mdi-play</v-icon>
                  <v-icon v-else-if="item.filetype=='image'" dark>mdi-image</v-icon>
                </v-btn>
                <div class="text-caption grey--text text-right">{{ item.d }} {{ item.t }}</div>
              </v-col>
            </v-row>
            <p class="mb-2" v-if="item.analyse" v-html="item.analyse"></p>
            <p class="mb-2" v-if="item.empfehlung" v-html="item.empfehlung"></p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
      <v-card class="mt-5">
        <v-card-text class="text-center text-h5">Keine Baustellen vorhanden</v-card-text>
      </v-card>
      
    </div>
  </v-container>
</template>

<script>

import axios from 'axios';

  export default {
    name: 'meine_baustellen',

    data: () => ({
      items:[],
      loading: false,
      current_video: false,
      current_video_mime_type: false,
      current_image: false,
    }),
    props:["id"],
    computed:{
      mediadialog(){
        return this.current_video || this.current_image
      },
      myItems(){
        let i = Array.from(this.items).filter( item  => item.ziel_erreicht!='Y' && item.headline);
        return Array.from(i).reverse();
      },
      isTrainer(){
        if (window && window.myuser) return window.myuser.isTrainer
        return false;
      }
    },
    methods:{
      async init() {
        this.loading = true
        await axios.get(process.env.VUE_APP_APIURL+"meine_baustellen/"+this.id,{
          headers: {
            'token': localStorage.getItem('token')
          }
        }).then((r) => {
          this.loading = false
          r = r.data
          console.log(r)
          if(r.result){
            this.items = r.result.items
          }else{
            this.error = r.error
          }
          //this.$router.push('/home')
        }).catch((error) => {
          this.loading = false
          this.error = error.message
        })
      },
      timelineColor(item){
        if (item.viewcount>0) return "green";
        return "grey"
      },
      itemColor(item){
        if (item.ziel_erreicht=="Y") return "green";
        if (!item.analyse && !item.empfehlung) return "orange"
        return "primary"
      },
      ansehen(item){
        this.logView(item)
        var url = process.env.VUE_APP_APIURL+"mediafile/"+item.ID+"/"+item.itemtoken
        if (item.filetype=="video"){
          this.current_video = url
          this.current_video_mime_type = item.filemimetype
          this.current_image = false
        }else if (item.filetype=="image"){
          this.current_video = false
          this.current_video_mime_type = false
          this.current_image = url
        }else{
          this.current_video = false
          this.current_video_mime_type = false
          this.current_image = false
        }
      },
      async logView(item){
        await axios.post(process.env.VUE_APP_APIURL+"itemlog/"+item.ID,{},{
          headers: {
            'token': localStorage.getItem('token')
          }
        })
      },
    },
    mounted(){
      this.init()
    },
  }
</script>
<style scoped>
div>>>.v-timeline-item__body{
  top:-8px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px;
}
</style>